<template>
  <div class="d-flex dialogue-box justify-content-center has-header m-lg-5">
    <div class="dialogue-box-header">Choose your skin</div>

    <div class="container dialogue-box-inner p-lg-5">
      <div class="row">
        <div class="col-12 col-md-4">
          <Player :show-pose-controls="true" style="max-height: 150px;" class="mb-5 mb-md-0"/>
        </div>
        <div class="col-12 col-md-8 skin-chooser pb-5 pb-lg-0">
          <SkinChooser/>
        </div>
      </div>
    </div>
    <div class="container dialogue-box-inner position-absolute dialogue-bottom-bar d-none d-lg-block">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="dialogue-box-bottom-header">
            Current
          </div>
        </div>
        <div class="col-12 col-md-8">
          <div class="dialogue-box-bottom-header">
            Choices
          </div>
        </div>
      </div>
    </div>
    <div class="dialogue-bg"></div>
  </div>
</template>
<style scoped lang="scss">
@media screen and (min-width: 992px) {

  div.dialogue-box {
    height: calc(100% - 125px);
  }
  .dialogue-box-inner {
    padding-bottom: 35px;
  }
  .dialogue-box, .container:not(.dialogue-bottom-bar), .row, div[class*=col] {
    height: calc(100%);
  }
  .skin-chooser {
    overflow: scroll;
  }
}

</style>
<script>
import Player from "@/components/player/Player";
import SkinChooser from "@/components/player/SkinChooser";

export default {
  components: {SkinChooser, Player}
}
</script>