<template>
  <div class="d-flex flex-row flex-wrap justify-content-center">
    <img :src="`/img/skins/cropped/relaxed_skin_${i}.png`" v-for="i in 10" :key="i" class="skin m-2" :class="{'active-skin': i === currentSkin}" @click="selectSkin(i)" />
  </div>
</template>
<style scoped lang="scss">
.skin {
  max-width: 150px;
}

.active-skin {
  filter: drop-shadow(2px 2px 15px #1cd91c);
}
</style>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'SkinChooser',
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      currentSkin: "user/skin"
    })
  },
  methods: {
    selectSkin(skin) {
      this.$socket.emit("set skin", skin)
    }
  }
}
</script>